@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}
