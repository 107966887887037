.error-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #F5222D; /* Border color of selected radio */
  background-color: #F5222D; /* Inner dot color of selected radio */
}

.success-radio-group .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #389E0D; /* Border color of selected radio */
  background-color: #389E0D; /* Inner dot color of selected radio */
}

.dangerousText > p  {
  margin: 0 !important; 
}