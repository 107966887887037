.teacher-tabContent-treenode {
  padding: 0 !important;
}

.teacher-tabContent-treenode-switcher-open,
.teacher-tabContent-treenode-switcher-close {
  padding: 16px 0 !important;
  border-top: 1px solid #f5f5f5;

  &:first-child {
    border-top: 0;
  }

  .teacher-tabContent-node-content-wrapper:hover {
    background-color: transparent !important;
  }
}

.teacher-tabContent-switcher-noop {
  width: 0 !important;
}
